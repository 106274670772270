$display-font-family: 'Bebas Neue', sans-serif;

$headings-color-dark: white;

$primary: white;

$input-border-radius: 0;

$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;
$btn-border-width: 2px;
$btn-font-family: $display-font-family;
$btn-line-height: 1;

$component-active-color: black;
