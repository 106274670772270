@import './Assets/Variables';

.header {
  background: var(--bs-body-bg);
}

.header__picture {
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  height: 36px;
  position: relative;
  top: -2px;
  vertical-align: middle;
  width: 36px;
}

.header__link {
  border-bottom: 3px solid transparent;
  color: var(--bs-heading-color);
  font-family: $display-font-family;
  font-size: 20px;
  margin: 0 1.5rem;
  text-decoration: none;

  &:hover {
    border-bottom: 3px solid;
    border-bottom-color: var(--bs-heading-color);
    color: var(--bs-heading-color);
    text-decoration: none;
  }
}
