.project__lightbox {
  background: black;
}

.project__carousel {
  aspect-ratio: 2 / 1;
  .carousel {
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      filter: revert;
    }
    .carousel-indicators [data-bs-target] {
      background-color: #fff;
    }
  }
}

.project__photo {
  aspect-ratio: 2 / 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.project__thumbnail {
  aspect-ratio: 2 / 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}
